import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { color, font } from '../../styles/style';
import styled from '@emotion/styled';
import Handler from '../../Handler';
import { LangContext } from '../../contexts';

const MarketCardContainer = styled.article`
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    @media screen and (max-width: 1199px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 640px) {
      grid-template-columns: repeat(1, 1fr);
    }
    li {
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 100%;
      margin: 0;
      .img__box {
        display: flex;
        min-width: unset;
        max-width: unset;
        height: 260px;
        border-radius: 12px 12px 0 0;
        overflow: hidden;
        position: relative;
        background: linear-gradient(-45deg, #5d3be5, #ded6ff, #faf9ff);
        background-size: 200% 200%;
        animation: gradient 15s ease infinite;
        img {
          width: 100%;
        }
        .tag__box {
          position: absolute;
          display: flex;
          margin: 20px;
          .tag__item {
            margin-right: 10px;
            padding: 5px 15px;
            font-size: 14px;
            background: #fff;
            border: 1px solid ${color.$main500};
            color: ${color.$main500};
            border-radius: 20px;
          }
        }
        .complte__box {
          position: absolute;
          display: flex;
          right: 12px;
          bottom: 12px;
          width: 97px;
          height: 32px;
          background: #fff;
          border-radius: 4px;
          align-items: center;
          justify-content: center;
          span {
            font-size: 14px;
            margin-left: 4px;
            color: $--main-500;
          }
        }
      }
      .info__box {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        /* height: 100%; */
        .title__box {
          h2 {
            font-size: ${font.$title_04};
            font-weight: 600 !important;
            padding: 5px 0;
          }
          .id__box {
            font-size: ${font.$body_02};
            color: ${color.$black_900};
          }
        }
        .price__box {
          h3 {
            font-weight: 600;
            font-size: ${font.$title_01};
            span {
              font-weight: 400;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {  
      background-position: 0% 50%;
    }
  }
`;

export const TableMarketplace = (props) => {
  const { data, rowClick, callback } = props;
  const { t } = useTranslation();
  const { isKorean } = useContext(LangContext);
  console.log('Tablemarketplace------>', data)
  console.log(`Item ${data.id} - titleEn:`, data.titleEn);


  return (
    <MarketCardContainer className='table-marketplace'>
      <ul>
        {data && data.length === 0 && <span>데이터가 없습니다.</span>}
        {data &&
          data.map((item, index) => {
            return (
              <li
                key={index + 'marketplace-data'}
                onClick={() => {
                  rowClick(item.seq);
                }}
              >
                <div className='img__box'>
                  <div className='tag__box'>
                    {item.methodology.area && (
                      <div className='tag__item'>{t(Handler.ENUM.AREA_TYPE(item.methodology.area))}</div>
                    )}
                    {item.methodology.subArea && (
                      <div className='tag__item'>{t(Handler.ENUM.AREA_TYPE(item.methodology.subArea))}</div>
                    )}
                  </div>
                  {item.mainImages && item.mainImages.length > 0 && (
                    <img src={item.mainImages[0]} alt={`project-thumbnail-${item.seq}`}></img>
                  )}
                  {item.saleStatus === 'SoldOut' && (
                    <div className='complte__box'>
                      <div className='ico complete-check'></div>
                      <span>판매 완료</span>
                    </div>
                  )}
                </div>
                <div className='info__box'>
                  <section className='title__box'>
                    <div className='id__box'>{item.id}</div>
                    <h2>{isKorean ? item.title : item.titleEn || 'No Title Available'}</h2>

                  </section>
                  <section className='price__box'>
                    <h3>
                      {Handler.numberWithCommas(item.salePrice)}
                      {t('WON')}
                      <span>/ VRC</span>
                    </h3>
                  </section>
                </div>
              </li>
            );
          })}
      </ul>
    </MarketCardContainer>
  );
};
